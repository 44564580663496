<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-atom">
  </ngx-spinner>
<div class="container">
  <div class="jumbotron">
    <h1>Compute Operator</h1>
    <h4>Login</h4>
    <br />
    <br />
    <form>
      <div class="row">
        <div class="col-md-6">
          <div class="col-md-12">
            <input class="form-control" autofocus  [(ngModel)]="username" name="username" required placeholder="Username"
              type="text">
          </div>
          <br />
          <div class="col-md-12">
            <input class="form-control" [(ngModel)]="password" type="password" name="password" required
              placeholder="Password" type="password">
          </div>
          <br />
          <div class="col-md-12">
            <button (click)="login()" type="submit">Login</button>
          </div>
          <br />
          {{message}}
        </div>
      </div>
    </form>
  </div>
</div>