import { Component, OnInit, Input } from '@angular/core';
import { CronCalendarService } from './cron-calendar.service';
import cronstrue from 'cronstrue';
//import cronstrue from 'cronstrue/i18n';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CronEditorCrudService } from './cron-editor-crud.service';
import { DatashareService } from 'src/app/shared/services/datashare.service';
import { CronEditorModel } from './cron-editor.model';
import { CronOptions } from 'src/app/References/cron-editor/CronOptions';
import { SelectItem } from 'primeng/api';
import { JobHistory } from './JobHistory.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cron-editor',
  templateUrl: './cron-editor.component.html',
  styleUrls: ['./cron-editor.component.css']
})
export class CronEditorComponent implements OnInit {
  cronExpression = '0/1 * 1/1 * *';
  lastExecution: string;
  nextExecution: string;
  secretKey: string;
  activeIndex = 0;

  public cronOptions: CronOptions = {
    formInputClass: 'form-control cron-editor-input',
    formSelectClass: 'form-control cron-editor-select',
    formRadioClass: 'cron-editor-radio',
    formCheckboxClass: 'cron-editor-checkbox',

    defaultTime: "00:00:00",

    hideMinutesTab: false,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: true,
    hideSpecificWeekDayTab: false,
    hideSpecificMonthWeekTab: false,

    use24HourTime: true,
    hideSeconds: false,

    cronFlavor: "standard" //standard or quartz
  };


  cronparser = require('cron-parser');
  cronExpressionRead: string;
  cronExpressionNext: string;
  //cronExpression: string;

  minutes: SelectItem[];
  hours: SelectItem[];
  days: SelectItem[];
  timeZones: SelectItem[];
  authorizationModes: SelectItem[];

  cronJobs: CronEditorModel[] = [];
  jobHistory: JobHistory[] = [];

  jobSchForm = this.formbulider.group({
    _id: ['', [Validators.required]],
    serviceEndPoint: ['', [Validators.required]],//, ValidatorNext.urlValidator]],
    serviceParameters: [''],
    serviceActions: ['', [Validators.required]],
    // userName: ['', [Validators.required]],
    // password: ['', [Validators.required]],
    // secretKey: ['', [Validators.required]],
    jobQueue: [''],
    cronExpression: [''],
    timeZone: ['', [Validators.required]],
    lastExecution: [''],
    nextExecution: [''],
    authorization: ['None', Validators.required],
    clientId: [''],
    clientSecret: [''],
    tokenEndPoint: [''],
    scope: ['']
  });

  constructor(
    private cronCalendarService: CronCalendarService,
    private formbulider: FormBuilder,
    private cronEditorCrudService: CronEditorCrudService,
    private shareService: DatashareService,
    private router: Router
  ) {

    this.minutes = cronCalendarService.getMintues();
    this.hours = cronCalendarService.getHours();
    this.days = cronCalendarService.getDays();
    this.timeZones = cronCalendarService.getTimeZones();
    this.authorizationModes = [
      { value: 'None', label: 'None' },
      { value: 'OAuth20', label: 'OAuth 2.0' }
    ];
    cronEditorCrudService.getAllJobs()
      .subscribe(
        result => {
          console.log(result);
          this.cronJobs = result;
          //console.log(this.cronJobs[0].timeZone.value.toString());

        },
        error => console.error(error));
  }

  onMinuteDropdownChanges(sender: any) {
    var value = sender.value as SelectItem;
    //this.cronExpression = "*/" + value.code + " * * * *"
    // this.cronExpression = '0 0 0 0 0';
    this.cronExpressionRead = cronstrue.toString(this.cronExpression);
    this.cronExpressionNext = this.cronparser.parseExpression(this.cronExpression).next().toString();
  }

  ngOnInit() {

  }
  // onRestForm()
  // {
  //   this.jobSchForm.reset();
  //   return false;
  // }
  onFormSubmit() {
    var JobSch = this.jobSchForm.value as CronEditorModel;
    var cronEditorModel: CronEditorModel = new CronEditorModel();;
    cronEditorModel._id = JobSch._id;
    cronEditorModel.cronExpression = this.cronExpression
    cronEditorModel.serviceEndPoint = JobSch.serviceEndPoint;
    cronEditorModel.serviceActions = JobSch.serviceActions;
    cronEditorModel.serviceParameters = JobSch.serviceParameters;
    cronEditorModel.secretKey = JobSch.secretKey;
    cronEditorModel.timeZone = JobSch.timeZone;
    cronEditorModel.authorization = JobSch.authorization;
    cronEditorModel.clientId = JobSch.clientId;
    cronEditorModel.clientSecret = JobSch.clientSecret;
    cronEditorModel.tokenEndPoint = JobSch.tokenEndPoint;
    cronEditorModel.scope = JobSch.scope;

    this.cronEditorCrudService.createJob(cronEditorModel)
      .subscribe(result => {
        console.log(result);
        this.resetForm();
        this.cronEditorCrudService.getAllJobs()
          .subscribe(
            result => {
              this.cronJobs = result;
              this.activeIndex = 1;

            },
            error => console.error(error));

      });




  }

  resetForm() {
    this.jobSchForm.reset();
    this.jobSchForm.get('authorization').setValue('None');
  }

  onEdit(event: any, id: string) {
    this.cronEditorCrudService.getJobById(id).subscribe(jobData => {
      this.jobSchForm.setValue(jobData);
      this.cronExpression = jobData.cronExpression;
      this.cronExpressionRead = cronstrue.toString(this.cronExpression);
      this.cronExpressionNext = this.cronparser.parseExpression(this.cronExpression).next().toString();
      this.lastExecution = jobData.lastExecution;
      this.nextExecution = jobData.nextExecution;
      this.activeIndex = 0;
    });

  }
  onDelete(event: any, id: string) {

    this.cronEditorCrudService.deleteJobById(id).subscribe(
      result => {
        console.log(result);
        this.cronEditorCrudService.getAllJobs()
          .subscribe(cj => { this.cronJobs = cj });
      },
      error => console.error(error));
  }

  changeAuthorizationMode(event: any) {
    const validator = event.value === 'None' ? null : Validators.required;
    this.jobSchForm.get('clientId').setValidators(validator);
    this.jobSchForm.get('clientId').updateValueAndValidity();
    this.jobSchForm.get('clientSecret').setValidators(validator);
    this.jobSchForm.get('clientSecret').updateValueAndValidity();
    this.jobSchForm.get('tokenEndPoint').setValidators(validator);
    this.jobSchForm.get('tokenEndPoint').updateValueAndValidity();
  }

  logout() {
    localStorage.removeItem('userData');
    this.router.navigate([`/login`]);
  }
}

