<div class="container">
  <div style="text-align: right">
    <button (click)="logout()">Logout</button>
  </div>
  <p-panel header="Job Scheduler">
    <p-tabView [(activeIndex)]="activeIndex">
      <p-tabPanel header="Details">
        <form [formGroup]="jobSchForm" (ngSubmit)="onFormSubmit()">
          <p-panel header="Time Zones">
            <div class="row">
              <div class="col-md-2">
                <span>Please Select: </span>
              </div>
              <div class="col-md-4">
                <p-dropdown autoWidth="false" [style]="{'width':'100%'}" [options]="timeZones"
                  formControlName="timeZone" placeholder="Required">
                </p-dropdown>
              </div>
            </div>
          </p-panel>
          <br>
          <cron-editor [(cron)]="cronExpression" [options]="cronOptions"></cron-editor>
          <br>
          <p-panel header="Cron Expressoin">
            <span>{{cronExpression}} </span>
            <br>
            <!-- <span>{{cronExpressionRead}} </span>
              <br>
              <span>Next : {{cronExpressionNext}} </span> -->
          </p-panel>
          <p-panel header="Job Execution">
            <span>Last Execution:<b> {{lastExecution}} </b></span>
            <br>
            <span>Next Execution: <b>{{nextExecution}} </b></span>
            <br>
          </p-panel>
          <br>

          <div class="container">

            <h3>Endpoint info</h3>
            <div class="row py-2 justify-content-center">
              <div class="col-md-2">

                <span>Base Endpoint: </span>
              </div>
              <div class="col-md-6">

                <input class="form-control" type="text" pInputText placeholder="Website"
                  formControlName="serviceEndPoint" placeholder="Required">
                <small class="form-text text-muted"
                  *ngIf="jobSchForm.get('serviceEndPoint').touched && jobSchForm.get('serviceEndPoint').hasError('invalidUrl')">Give
                  proper url</small>
              </div>

            </div>
            <div class="row py-2 justify-content-center">
              <div class="col-md-2">
                <span>Service Actions: </span>
              </div>
              <div class="col-md-6">
                <input class="form-control" type="text" pInputText icon="pi pi-check" formControlName="serviceActions"
                  placeholder="Required">
              </div>
            </div>
            <div class="row py-2 justify-content-center">
              <div class="col-md-2">
                <span>Service Parameters: </span>
              </div>
              <div class="col-md-6">
                <textarea class="form-control" rows="10" placeholder="Required" pInputTextarea
                  formControlName="serviceParameters"></textarea>
              </div>
            </div>
            <div class="row py-2 justify-content-center">
              <div class="col-md-2">
                <span>Job Name: </span>
              </div>
              <div class="col-md-6">
                <input class="form-control" type="text" pInputText formControlName="_id" placeholder="Required">
              </div>
            </div>
            <h4>Authorization</h4>

            <div class="row py-2 justify-content-center">
              <div class="col-md-2">
                <span>Mode: </span>
              </div>
              <div class="col-md-6">
                <p-dropdown autoWidth="false" (onChange)="changeAuthorizationMode($event)" [style]="{'width':'100%'}"
                  [options]="authorizationModes" formControlName="authorization" placeholder="Authorization Mode">
                </p-dropdown>
              </div>
            </div>

            <ng-container *ngIf="jobSchForm.get('authorization').value !== 'None'">
              <div class="row py-2 justify-content-center">
                <div class="col-md-2">
                  <span>Client Id: </span>
                </div>
                <div class="col-md-6">
                  <input class="form-control" type="text" pInputText formControlName="clientId" placeholder="Required">
                </div>
              </div>

              <div class="row py-2 justify-content-center">
                <div class="col-md-2">
                  <span>Client Secret: </span>
                </div>
                <div class="col-md-6">
                  <input class="form-control" type="text" pInputText formControlName="clientSecret"
                    placeholder="Required">
                </div>
              </div>

              <div class="row py-2 justify-content-center">
                <div class="col-md-2">
                  <span>Token Endpoint: </span>
                </div>
                <div class="col-md-6">
                  <input class="form-control" type="text" pInputText formControlName="tokenEndPoint"
                    placeholder="Required">
                </div>
              </div>

              <div class="row py-2 justify-content-center">
                <div class="col-md-2">
                  <span>Scope: </span>
                </div>
                <div class="col-md-6">
                  <input class="form-control" type="text" pInputText formControlName="scope" placeholder="Optional">
                </div>
              </div>
            </ng-container>
            <div class="row justify-content-center">
              <div class="col-md-8">
                <button pButton type="submit" icon="pi pi-plus" label="Save" class="btn-sm btn-primary float-right"
                  style="margin-right: .25em" [disabled]="!jobSchForm.valid"></button>


                <button pButton type="reset" icon="pi pi-plus" label="New" class="btn-sm btn-primary float-right"
                  style="margin-right: .25em" (onClick)="resetForm()"></button>
              </div>
            </div>
          </div>


        </form>
      </p-tabPanel>
      <p-tabPanel header="Job List">
        <p-table [value]="cronJobs">
          <ng-template pTemplate="header">
            <tr>
              <th>Id</th>
              <th>Queue Name</th>
              <th>Cron</th>
              <th>Time Zone</th>
              <th>Last Execution</th>
              <th>Next Execution</th>
              <th>Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-cronJob>
            <tr>
              <td>{{cronJob._id}}</td>
              <td>{{cronJob.jobQueue}}</td>
              <td>{{cronJob.cronExpression}}</td>
              <td>{{cronJob.timeZone}}</td>
              <td>{{cronJob.lastExecution}}</td>
              <td>{{cronJob.nextExecution}}</td>
              <td style="text-align: center">
                <p-button icon="pi pi-pencil" iconPos="left" class="mr-1"
                  (onClick)="onEdit($event, cronJob._id)"></p-button>
                <p-button icon="pi pi-times" iconPos="left" class="ml-1"
                  (onClick)="onDelete($event, cronJob._id)"></p-button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
    </p-tabView>
    <br>
  </p-panel>
</div>