import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Compute Operator';

  public clickedEvent: Event;

  ngOnInit() {
  }
  constructor(private router: Router){
    
  }

  childEventClicked(event: Event) {
    this.clickedEvent = event;
  }
}
